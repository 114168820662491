<template>
    <v-app>
        <div id="app">
            <Sidebar />

            <router-view />
        </div>
    </v-app>
</template>

<script>
import "./style.css";

import { mapActions } from "vuex";

import Sidebar from "./components/Sidebar-menu.vue";

export default {
    components: { Sidebar },
    methods: {
        ...mapActions({
            getUserData: "getUserData",
            getQrcodes: "getQrcodes",
            getUserNotification: "getUserNotification",
            onResize: "onResize",
        }),
    },
    mounted() {
        this.getUserData();
        this.getQrcodes();
        this.getUserNotification();
        this.onResize();
        window.addEventListener("resize", this.onResize);
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
};
</script>
