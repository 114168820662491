var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebarContainer"},[_c('div',{staticClass:"sidebar-logo",on:{"click":function($event){_vm.$router.push({ name: 'profile' }).catch(() => {})}}},[_c('img',{staticClass:"header__img",attrs:{"src":require("../assets/logo.svg"),"alt":"логотип"}})]),_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"deep-purple--text text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[(!this.$store.state.isActivetedUser)?_c('v-list-item',{class:{
                    activeItem: _vm.$route.path === '/apruve-email',
                    notActiveItem: _vm.$route.path !== '/apruve-email',
                },on:{"click":function($event){_vm.$router.push({ name: 'apruve-email' }).catch(() => {})}}},[_c('v-list-item-title',[_vm._v("Подтвердить email")])],1):_vm._e(),_c('v-list-item',{class:{
                    activeItem: _vm.$route.path === '/sticker',
                    notActiveItem: _vm.$route.path !== '/sticker',
                },attrs:{"aria-selected":"false"},on:{"click":function($event){_vm.$router.push({ name: 'sticker' }).catch(() => {})}}},[_c('v-list-item-title',[_vm._v("Получить стикеры")])],1),(this.$store.state.isActivetedUser)?_c('v-list-item',{class:{
                    activeItem: _vm.$route.path === '/profile',
                    notActiveItem: _vm.$route.path !== '/profile',
                },attrs:{"aria-selected":"true"},on:{"click":function($event){_vm.$router.push({ name: 'profile' }).catch(() => {})}}},[_c('v-list-item-title',[_vm._v("Мой профиль")])],1):_vm._e(),_c('v-list-item',{class:{
                    activeItem: _vm.$route.path === '/upload',
                    notActiveItem: _vm.$route.path !== '/upload',
                },attrs:{"aria-selected":"false"},on:{"click":function($event){_vm.$router.push({ name: 'LcUpload' }).catch(() => {})}}},[_c('v-list-item-title',[_vm._v("Загрузить новый чек")])],1),_c('v-list-item',{class:{
                    activeItem: _vm.$route.path === '/lc/',
                    notActiveItem: _vm.$route.path !== '/lc/',
                },attrs:{"aria-selected":"false"},on:{"click":function($event){_vm.$router.push({ name: 'LcMain' }).catch(() => {})}}},[_c('v-list-item-title',[_vm._v("Загруженные чеки")])],1),_c('v-list-item',{class:{
                    activeItem: _vm.$route.path === '/notification',
                    notActiveItem: _vm.$route.path !== '/notification',
                }},[_c('v-list-item-title',{staticClass:"sidebar-notifications",on:{"click":function($event){_vm.$router.push({ name: 'notifications' }).catch(() => {})}}},[_vm._v("Уведомления "),_c('div',{staticClass:"sidebar-notifications__counter"},[_vm._v(" "+_vm._s(this.$store.state.filteredNotification)+" ")])])],1),_c('div',{staticStyle:{"display":"flex"}},[_c('v-list-item',{on:{"click":_vm.gameLink}},[_c('v-list-item-title',[_vm._v("Игра")])],1),_c('v-list-item',{on:{"click":_vm.showGameRules}},[_c('v-btn',{staticStyle:{"margin-top":"0"}},[_vm._v("Правила Игры")])],1)],1),_c('v-list-item',{class:{
                    activeItem: _vm.$route.path === '/prize',
                    notActiveItem: _vm.$route.path !== '/prize',
                },attrs:{"aria-selected":"false"},on:{"click":function($event){_vm.$router.push({ name: 'prize' }).catch(() => {})}}},[_c('v-list-item-title',{staticClass:"sidebar-notifications"},[_vm._v("Вручение призов "),_c('div',{staticClass:"sidebar-notifications__counter"},[_vm._v(" "+_vm._s(this.$store.state.prizeList.length)+" ")])])],1),_c('v-list-item',{class:{ notActiveItem: _vm.$route.path !== '/support' }},[_c('a',{attrs:{"href":'mailto:support@promo-chl.ru',"target":"_blank"}},[_c('v-list-item-title',[_vm._v("Поддержка")])],1)])],1)],1),(this.$store.state.gameRulesDesktop && this.$store.state.isNavBarOff)?_c('div',{staticClass:"iframe-container"},[_c('v-btn',{staticClass:"close-rules",on:{"click":_vm.toggleShowGameRules}},[_vm._v(" Закрыть ")]),_c('iframe',{attrs:{"src":"https://promo-chl.ru/game-rules","frameborder":"0"}})],1):_vm._e(),_c('p',{staticClass:"sidebar-exitBtn",on:{"click":_vm.exit}},[_vm._v("Выйти")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }