<template>
    <div class="wrapper-profile">
        <div class="v-form">
            <h2>Получить стикеры</h2>
            <p>Для получения стикерпака Чистая линия - перейдите по ссылке</p>
            <v-btn depressed color="primary" class="next-btn" block @click="getStickerPackLink">
                <div class="btn-content-container">
                    <img
                        src="@/assets/loader.svg"
                        alt="loader"
                        class="loader-icon"
                        :class="{ hidden: !isLoading }"
                    />
                    Получить стикеры
                </div>
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        getStickerPackLink() {
            window.open("https://t.me/addstickers/vkusnoe_leto");
        },
    },
};
</script>

<style scoped>
@media screen and (max-width: 4000px) and (min-width: 769px) {
    .wrapper-profile {
        max-width: 800px;
        width: 100%;
    }

    .v-form {
        border-radius: 16px;
        padding: 60px;
    }
}
</style>
